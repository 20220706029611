import React from "react";
import {Link} from "react-router-dom";

// function ListButton(){
//     // var x = document.getElementById("mainav");
//     // x.className = "fl_right listbutton";
//     // if (x.className === "fl_right") {
//     //     x.className += " listbutton";
//     //   } else {
//     //     x.className = "fl_right";
//     //   }
//     console.log("button pressed");
// }

function Header(){

    return (
        
        
        <div className="wrapper row1">
        <header id="header" className="hoc clear"> 
      
        <div id="logo" className="fl_left">
          <h1 style={{fontSize: 25 , fontWeight: "bold"}} ><Link to="/">Norwood Lanka Teas International </Link></h1>
        </div>
        <nav id="mainav" className="fl_right" style = {{fontSize : "14px"}}>
          <ul className="clear">
            <li ><Link to="/">Home</Link></li>
            
            
            <li><Link to="/products">Products</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
            <li><Link to="/aboutus">About Us</Link></li>
            {/* <li><Link to="/cart"><i className="fas fa-shopping-cart"></i></Link></li> */}
           
          </ul>
          <a id = "menueiconid"className="" onClick={() => { var x = document.getElementById("mainav");
          var y = document.getElementById("menueiconid");
         if (x.className === "fl_right") {
            x.className = " mainlistvis carticon";
            y.className = "menueicon";
          } else {
          x.className = "fl_right";
        
          }}}><i className="fa fa-bars menicon" ></i></a>
        </nav>
        
      </header>
      </div>
     
    )


}
export default Header;