import {Link} from 'react-router-dom';
import Header from "./Header";

function Products() {

    window.scrollTo(0,0);
    
    return ( 
        <div>
        <div className="bgded bgimg2" > 
        <Header/>

        <section id="pageintro" className="hoc clear">
            <div> 
        
            
            </div>
        </section>

        </div>


        <div className="wrapper row3">
        <main className="hoc container clear btmpad-none"> 
        <h6>Order Confirmation</h6>
            <p style = {{fontSize : "14px"}}>After placing the order through online one of our emloyees will collect your e-mail and contact you.</p>
            <br/>
           
        </main>
        </div>

                <div className="wrapper bgded overlay coloured">
                    <section className="hoc container clear"> 
                
                    <div className="sectiontitle center">
                        <h6 className="heading">Food Categories</h6>
                    
                    </div>
                    <ul className="infoboxes plist">
                        <li className="one_third" >
                        <article className="infobox" >
                    
                            <img src = "images/1.jpg"  alt = "product 1" className= "product" />
                           <Link to="/cart/masala"><h6>Masala</h6><i className="fas fa-cart-plus"></i></Link>

                            
                        </article>
                        
                        </li>
                        <li className="one_third">
                        <article className="infobox" >
                            <img src = "images/2.jpg"  alt = "product 1" className= "product"/>

                            <Link to="/cart/o-peanut"><h6 className = "pname">Oil Peanuts</h6><i className="fas fa-cart-plus"></i></Link>

                
                        </article>
                            
                        </li>
                
                        <li className="one_third">
                        <article className="infobox">
                            <img src = "images/3.jpg"  alt = "product 1" className= "product" />

                            <Link to="/cart/kotu-murukku"><h6>Kotu Murukku</h6><i className="fas fa-cart-plus"></i></Link>

                            
                        </article>
                        </li>
                
                        <li className="one_third second">
                        <article className="infobox">
                            <img src = "images/4.jpg"  alt = "product 1" className= "product" />

                            <Link to="/cart/ribbon-b"><h6>Ribbon Bite</h6><i className="fas fa-cart-plus"></i></Link>

                            
                        </article>
                        </li>
                        <li className="one_third second">
                        <article className="infobox">
                            <img src = "images/5.jpg"  alt = "product 1" className= "product" />

                            <Link to="/cart/mixture"><h6>Mixture</h6><i className="fas fa-cart-plus"></i></Link>

                            
                        </article>
                        </li>
                    
                        <li className="one_third second">
                        <article className="infobox">
                            <img src = "images/7.jpg"  alt = "product 1" className= "product" />

                            <Link to="/cart/r-peanut"><h6>Roast Peanuts</h6><i className="fas fa-cart-plus"></i></Link>

                            
                        </article>
                        </li>
                    
                        <li className="one_third second">
                        <article className="infobox">
                            <img src = "images/6.jpg"  alt = "product 1" className= "product" />

                            <Link to="/cart/chips"><h6>Chips</h6><i className="fas fa-cart-plus"></i></Link>
                            
                        </article>
                    
                        </li>

                   
                        <li class="one_third">
                        <article class="infobox" >
                            <img src = "images/21.jpg"  alt = "product 1" class= "product"/>

                            <p class = "pname"><Link to="/cart/milk-toffee"><h6 class = "pname">Milk Toffee</h6><i class="fas fa-cart-plus"></i></Link></p>

                
                        </article>
                            
                        </li>
                        <li class="one_third">
                        <article class="infobox" >
                            <img src = "images/22.jpg"  alt = "product 1" class= "product"/>

                            <p class = "pname"><Link to="/cart/ribbon"><h6 class = "pname">Ribbon</h6><i class="fas fa-cart-plus"></i></Link></p>

                
                        </article>
                            
                        </li>
                        <li class="one_third">
                        <article class="infobox" >
                            <img src = "images/23.jpg"  alt = "product 1" class= "product"/>

                            <p class = "pname"><Link to="/cart/chips-bite"><h6 class = "pname">Chips Bite</h6><i class="fas fa-cart-plus"></i></Link></p>

                
                        </article>
                            
                        </li>
                        <li class="one_third">
                        <article class="infobox" >
                            <img src = "images/24.jpg"  alt = "product 1" class= "product"/>

                            <p class = "pname"><Link to="/cart/ring-bite"><h6 class = "pname">Ring Bite</h6><i class="fas fa-cart-plus"></i></Link></p>

                
                        </article>
                            
                        </li>
                        <li class="one_third">
                        <article class="infobox" >
                            <img src = "images/25.jpg"  alt = "product 1" class= "product"/>

                            <p class = "pname"><Link to="/cart/bata"><h6 class = "pname">Bata</h6><i class="fas fa-cart-plus"></i></Link></p>

                
                        </article>
                            
                        </li>
                    </ul>
                
                    <div className="clear"></div>
                    </section>
                </div>

                </div>

     );
}

export default Products;
