import Header from "./Header";
export default function AboutUs (){

  window.scrollTo(0,0);

    return (
        <div> 
          <div className="bgded bgimg3" > 
            <Header/>
          </div>

            <div className="wrapper row3">
            <main className="hoc container clear"> 
              
              <h1 style={{fontWeight: "bold" , fontSize: 35}} > About Us</h1>
              <p style={{fontSize: 22 }}>Our values</p>
              <div style = {{fontSize : "14px"}}>
              <p style={{ fontWeight: "bold"}} >RELIABILITY AND Sincerity</p>
              <p> Our devotion to client service has helped us expand over the last five years. We promise to develop a bond of trust, sincerity, and respect, whether you're a retailer or internet distributor</p> 
                <p style={{ fontWeight: "bold"}} >REASOABLE PRICE AND MODEST</p>
                <p> From the beginning, equality has been a virtue that we have loved. We think that everyone should be able to enjoy original, high-quality Sri Lankan snacks at a fair price, regardless of their background.</p>
                <p style={{ fontWeight: "bold"}} > INNOVATORS WITH PASSION</p>
                <p> In our Sri Lankan recipes, we enjoy exploring. We try to reduce our carbon footprint whenever practicable, and using locally sourced foods is a fantastic chance to try out new, robust hybrid flavors.</p>

              </div>


              <div className="clear"></div>
            </main>
          </div>

        </div>
    )
}