import React from "react";
import {useState} from 'react';
import { useParams, useNavigate} from "react-router-dom";
import Header from "./Header";



function Cart(props) {

  let pName;
  let pImage;
   
  const paramList = useParams();
    
  if(paramList.id == "masala"){
      pName = 'Masala';
      pImage = 1;

  }
  else if(paramList.id == "o-peanut"){
      pName = 'Oil Peanuts';
      pImage = 2;

  }
  else if(paramList.id == "kotu-murukku"){
      pName = 'Kotu Murukku';
      pImage = 3;

  }

  else if(paramList.id == "ribbon-b"){
      pName = 'Ribbon Bite';
      pImage = 4;

  }
  else if(paramList.id == "mixture"){
      pName = 'Mixtures';
      pImage = 5;

  }
  else if(paramList.id == "r-peanut"){
      pName = 'Roast Peanut';
      pImage = 7;

  }
  else if(paramList.id == "chips"){
      pName = 'Chips';
      pImage = 6;
  }
  else if(paramList.id == "milk-toffee"){
      pName = 'Milk Toffee';
      pImage = 21;
  }
  else if(paramList.id == "ribbon"){
      pName = 'Ribbon';
      pImage = 22;
  }
  else if(paramList.id == "chips-bite"){
      pName = 'Chips Bite';
      pImage = 23;
  }
  else if(paramList.id == "ring-bite"){
      pName = 'Ring Bite';
      pImage = 24;
  }
  else if(paramList.id == "bata"){
      pName = 'Bata';
      pImage = 25;
  }


  const [count, setCount] = useState(0);
  const [countEmail, setCountEmail] = useState(0);

  
  const [product, setProduct] = useState(pName);
  const [qty, setQty] = useState('');
  const [cname, setCName] = useState('');
  const [clocation, setCLocation] = useState('');
  const [cphone, setCPhone] = useState('');

  const [name, setName] = useState('testName');
  const [email, setEmail] = useState('');

 

    if (count == 0){
      window.scrollTo(0,0);
      console.log('refreshed');
      setCount(count+1);
      
    }
    

      function handlePQtyChange(event) {
        setQty(event.target.value);
        
      }
      function handleCNameChange(event) {
        setCName(event.target.value);
        
      }
      
      function handleEmailChange(event){
          setEmail(event.target.value);
      }
      function handleCLocationChange(event) {
        setCLocation(event.target.value);
        
      }
      function handleCPhoneChange(event) {
        setCPhone(event.target.value);
        
      }
    
    function handleSubmit (event) {
        const templateId = 'template_ocv2rh8';
        const customerTemplate = 'template_z43u06l';
        setProduct(pName);

        if(qty == 0 || cname == "" || clocation == "" || cphone == "" || email == ""){
          window.alert("All fields must be filled !!!");
        }
        else if ((!email.includes("@")|| (!email.includes(".")))){
          window.alert("Enter Valid Email !!!");
        }
        else if (cphone.length != 10){
          window.alert("Enter Valid Phone Number !!!");
        }
        else{
          console.log("order placed");
          sendFeedback(templateId, {product_name: product, product_qty : qty, customer_name : cname,
            customer_location: clocation, customer_phone : cphone, from_name: name, reply_to: email})

          sendFeedback(customerTemplate, {product_name: product, product_qty : qty, customer_name : cname,
            customer_location: clocation, customer_phone : cphone, from_name: name, send_to: email})
        }
        
      }
    
    let navigate = useNavigate();

    function  sendFeedback (templateId, variables) {
        window.emailjs.send(
          'service_gvb726n', templateId,
          variables
          ).then(res => {
            console.log('Email successfully sent!');
            if (countEmail == 0){
              navigate("/orderstatus/success", { replace: true });
            }
            setCountEmail(countEmail+1);
            
            
          })
          // Handle errors here however you like, or use a React error boundary
          .catch(err => {
            console.error('Oh well, you failed. Here some thoughts on the error that occured:', err);
            navigate("/orderstatus/failure", { replace: true });
          })
      }




    return (
    <div>

      
         <div className="bgded bgimg3" > 
            <Header/>
            
          </div>
        <div className="wrapper bgded overlay coloured">
            <section className="hoc container clear"> 
        
            <div className="sectiontitle center">
                <h6 className="heading">Cart</h6>

            </div>
            <div className="formdiv">
            <div className= "cartpic">
                
                
                <img src = {`/images/${pImage}.jpg`}  alt = "product 1" className= "product cartpic" />
                <br/><br/><h6>{pName}</h6>
                
            </div>
            <div style={{color : "black", fontSize : "14px"}}>
            
                
                
                    <label style={{color : "white", width : "50%" }} >Weight (g)</label>
               
              
                    <input type = "number" style={{color : "black" , width : "120px" , height : "30px"}} 
                    onChange={handlePQtyChange}
                    value={qty}
                    required></input>
                 
                    <label style={{color : "white"}} >Enter your name</label>
                    <input type  = "text"  style={{color : "black" , width : "60%" , height : "30px"}} 
                    onChange={handleCNameChange}
                    value={cname}
                    required></input>

                    <label style={{color : "white"}} >Enter your email</label>
                    <input type  = "email"  style={{color : "black" , width : "60%" , height : "30px"}} 
                    onChange={handleEmailChange}
                    value={email}
                    required></input>

                    <label style={{color : "white"}} >Enter your location</label>
                     <input type = "text"  style={{color : "black" , width : "60%" , height : "30px"}}
                     onChange={handleCLocationChange}
                     value={clocation}
                     required></input>

                    <label style={{color : "white"}} >Enter your contact number (with 10 digits)</label>
                    <input type = "number" style={{color : "black" , width : "60%" , height : "30px"}} 
                    onChange={handleCPhoneChange}
                    value={cphone}
                    required></input>

                    </div>
                    <br/>
                    <button type="submit" onClick={handleSubmit} className='orderbtn' style={{color : "black", fontSize : "14px"}}>Order</button>
         

            </div>
        
            <div className="clear"></div>
            </section>
        </div>

    </div>
    );
}

export default Cart;