import React from "react";
import {useState} from 'react';
import { useParams,Navigate } from "react-router-dom";
import Header from "./Header";

function OrderStatus() {


    window.scrollTo(0,0);
    const paramList = useParams();
    if(paramList.id == "success"){
        console.log("success page launched");

        return ( 
            <div>
        
                <div className="bgded bgimg3" > 
                    <Header/>
        
                   
        
                        </div>
                            <div className="wrapper row3">
                    <main className="hoc container clear"> 
        
                        <h2 className="cuHeader" style={{ fontWeight: "bold" , fontSize: 32}} >Order Successfully Placed</h2>
                        <p className="cuParasamll" style = {{fontSize : "16px"}}>You will be contacted by one of our agent soon.
                        <br/>
                        Thank You for using Norwood Lanka Teas International products. <br/>
                        Order receipt has been sent to your Email. If you couldn't find one please check the spam folder.
                        </p>
                        
        
                        
                        <div className="clear"></div>
                    </main>
                    </div>
            </div>
            
            )


    }
    else{
        console.log("failure page launched");
        return ( 
            <div>
        
                <div className="bgded bgimg3" > 
                    <Header/>
        
                   
        
                        </div>
                            <div className="wrapper row3">
                    <main className="hoc container clear"> 
        
                        <h2 className="cuHeader" style={{ fontWeight: "bold" , fontSize: 32}} >Failure</h2>
                        <p className="cuParasamll" style = {{fontSize : "14px"}}>Order couldn't place due to technical failure
                        <br/>
                        Please try again in short time.
                        </p>
                        
        
                        
                        <div className="clear"></div>
                    </main>
                    </div>
            </div>
            
            )
    }

    
    

}

export default OrderStatus;