
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import Footer from './components/Footer';
import Products from './components/Products';
import Cart from './components/Cart';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import OrderStatus from './components/OrderStatus';



function App() {
  return (
    <Router>
     
    <Routes>
      <Route path="/"  element={<Home />}/>

      <Route path="/products" element={<Products/>}/>
      <Route path="/cart/:id" element={<Cart/>}/>

      <Route path="/contactus" element={<ContactUs/>}/>
      <Route path="/aboutus" element={<AboutUs/>}/>
      <Route path="/orderstatus/:id" element= {<OrderStatus/>}/>
 


   
    </Routes>
    <Footer/>
    </Router>

  );
}

export default App;
