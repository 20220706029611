import Header from "./Header";
export default function ContactUs(){

    window.scrollTo(0,0);

    return (
        <div> 
          <div className="bgded bgimg3" > 
            <Header/>

           

        </div>
            <div className="wrapper row3">
    <main className="hoc container clear"> 

        <h2 className="cuHeader" style={{ fontWeight: "bold" , fontSize: 32}} >Contact Us</h2>
        <p className="cuParasamll" style = {{fontSize : "14px"}}>Presently, our production line includes a total of 8 different types of products, with direct employment of 150 people and distribution centered on an island-wide network including 7000 outlets.</p>
        <p className="cuParaLarge" style={{ fontWeight: "bold" , fontSize: 19}}>ARE YOU  LOOKING TO GET IN TOUCH?</p>
        <p className="cuParasamll" style = {{fontSize : "14px"}}> Please email our Digital Newsroom Team.</p> 
        <p className="cuParasamll" style={{ fontWeight: "bold" , fontSize: 15}}>Email: norwoodlankateasinternational@gmail.com</p>
        <p className="cuParasamll" style={{ fontWeight: "bold" , fontSize: 15}}> Phone: 0716195982</p>
        <p className="cuParasamll" style={{ fontWeight: "bold" , fontSize: 15}}> Postal address:<br/>
        4/319,<br/>Delgahawatha road<br/> Athurugiriya 10150,<br/>Sri Lanka<br/>
            </p>

        
    <div className="clear"></div>
  </main>
</div>
        </div>
    )
}
