function Footer() {
    return ( 
        <div>

            
<div className="wrapper row4 bgded overlay" >
  <footer id="footer" className="hoc clear"> 


    <div className="one_third" style = {{fontSize : "16px"}}>
      <h6 className="heading" style = {{fontSize : "18px"}}>Norwood Lanka Teas Internatiolnal</h6>
      <ul className="nospace btmspace-30 linklist contact">
        <li><i className="fa fa-map-marker"></i>
          <address>
          4/319,<br/>Delgahawatha road<br/> Athurugiriya 10150,<br/>Sri Lanka<br/>
          
          </address>
        </li>
        <li><i className="fa fa-phone"></i>0716195982</li>
        
        <li><i className="fa fa-envelope-o"></i> norwoodlankateasinternational@gmail.com</li>
      </ul>
      <ul className="faico clear">
        <li><a className="faicon-facebook" href="https://www.facebook.com/norwoodteasinternational/"><i className="fa fa-facebook"></i></a></li>
        {/* <li><a className="faicon-twitter" href="#"><i className="fa fa-twitter"></i></a></li>
        <li><a className="faicon-dribble" href="#"><i className="fa fa-dribbble"></i></a></li>
        <li><a className="faicon-linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>
        <li><a className="faicon-vk" href="#"><i className="fa fa-vk"></i></a></li> */}
        <li><a className="faicon-google" href="#"><i className="fa fa-google"></i></a></li>
      </ul>
    </div>
    
      <div className="one_third branchsec">
        <p className="heading" style = {{fontSize : "18px"}}>Find our locations :</p>
     
        <p style = {{fontSize : "16px"}}>Athurugiriya, Malabe, Kaduwela, Kadawatha, Matara, Hambanthota, Ambalanoda, Ambalanthota,<br/>Kathragama, Galle</p>
        
        
      
      </div>
 
  
 
  </footer>
</div>
            <div className="wrapper row5" style = {{fontSize : "14px"}}>
            <div id="copyright" className="hoc clear"> 

                <p className="fl_left">Copyright &copy; 2022 - All Rights Reserved - <a href="#">Norwood Lanka Teas International</a></p>
                
            </div>
            </div>

            <a id="backtotop" href="#top"><i className="fa fa-chevron-up"></i></a>
                    </div>

     );
}

export default Footer;