import React from "react";
import {Link} from "react-router-dom";
import Header from "./Header";

export default function Home(){

    window.scrollTo(0,0);

    return (
    
        <div>
       
  
        <div className="bgded bgimg1" > 

        <Header/>
        
        <section id="pageintro" className="hoc clear">
            <div> 
        
            
            </div>
        </section>

        </div>

       
        <div className="wrapper bgded overlay coloured">
        <section className="hoc container clear"> 

            <div className="sectiontitle center">
            <h6 className="heading">Food Categories</h6>

            </div>
            <div className = "sc-menue infoboxes">
            <Link to="/products"><article className="infobox " >
                
                <img src = "images/im1.jpeg" alt = "product 1" className= "product" />
                <br/><br/><h6>Masala</h6>
                </article></Link>

                <Link to="/products"><article className = "infobox">
                <img src = "images/10.jpeg"  alt = "product 1" className= "product"/>
                <br/><br/><h6>Murukku</h6>

                </article></Link>

                <Link to="/products"><article className="infobox">
                <img src = "images/11.jpeg"   alt = "product 1" className= "product" />
                <br/><br/><h6>Peanuts</h6>
                
                </article></Link>

                <Link to="/products"><article className="infobox">
                <img src = "images/12.jpeg"  alt = "product 1" className= "product" />
                <br/><br/><h6>Chips</h6>
                
                </article></Link>

                <Link to="/products"><article className="infobox">
                <img src = "images/21.jpg"  alt = "product 1" className= "product" />
                <br/><br/><h6>Sweets</h6>
                
                </article></Link>

            </div>

            <div className="clear"></div>
        </section>
        </div>

        <div className="wrapper row3">
        <main className="hoc container clear btmpad-none" style = {{fontSize : "14px"}}> 
            
            <h6>Payment and Shipping</h6>
            <p>After placing the order through online. Payment can be done in person or you can follow the instruction in email.</p>
            <p>After payment has been done your order can be delivered to your doorstep or can collect the order from any of our braches</p>
            <br/>
        </main>
        </div>


            </div>
        )


    }
